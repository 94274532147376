@charset "UTF-8";
body {
  font-family: "Noto Sans KR", "맑은 고딕", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", "Roboto", sans-serif;
  font-weight: 400; }
  body.desktop {
    font-size: 16px; }
    body.desktop main {
      margin-top: 104px; }

h1,
h2 {
  font-family: "Noto Sans KR", "맑은 고딕", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", "Roboto", sans-serif;
  font-weight: 700; }

h3,
h4,
h5 {
  font-family: "Noto Sans KR", "맑은 고딕", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", "Roboto", sans-serif;
  font-weight: 500;
  color: #585858; }

a {
  font-family: "Noto Sans KR", "맑은 고딕", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", "Roboto", sans-serif;
  font-weight: 400;
  color: #585858; }

a:hover {
  color: #333; }

a:active {
  color: #333; }

.alternative-font {
  color: #585858; }

p.drop-caps:first-child:first-letter {
  color: #585858; }

p.drop-caps.secundary:first-child:first-letter {
  background-color: #585858; }

ul.nav-pills > li.active > a {
  background-color: #585858; }

ul.nav-pills > li.active > a:hover,
ul.nav-pills > li.active > a:focus {
  background-color: #585858; }

.sort-source-wrapper .nav > li.active > a {
  color: #585858; }

.sort-source-wrapper .nav > li.active > a:hover,
.sort-source-wrapper .nav > li.active > a:focus {
  color: #585858; }

/* Labels */
.label-primary {
  background-color: #585858; }

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #585858; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #585858;
  border-color: #585858; }

body.dark .pagination > li > a,
body.dark .pagination > li > span,
body.dark .pagination > li > a:hover,
body.dark .pagination > li > span:hover,
body.dark .pagination > li > a:focus,
body.dark .pagination > li > span:focus {
  color: #585858; }

body.dark .pagination > .active > a,
body.dark .pagination > .active > span,
body.dark .pagination > .active > a:hover,
body.dark .pagination > .active > span:hover,
body.dark .pagination > .active > a:focus,
body.dark .pagination > .active > span:focus {
  background-color: #585858;
  border-color: #585858; }

.btn-primary,
.pagination > .active > a,
body.dark .pagination > .active > a,
.owl-theme .owl-controls .owl-nav [class*="owl-"] {
  border-color: #585858 !important;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #585858;
  border-color: #585858; }

.btn-primary:hover,
.pagination > .active > a:hover,
body.dark .pagination > .active > a:hover,
.owl-theme .owl-controls .owl-nav [class*="owl-"]:hover {
  border-color: #333;
  background-color: #333; }

.btn-primary:active,
.pagination > .active > a:active,
body.dark .pagination > .active > a:active,
.owl-theme .owl-controls .owl-nav [class*="owl-"]:active,
.btn-primary:focus,
.pagination > .active > a:focus,
body.dark .pagination > .active > a:focus,
.owl-theme .owl-controls .owl-nav [class*="owl-"]:focus {
  border-color: #333;
  background-color: #333; }

.progress-bar-primary {
  background-color: #585858; }

.btn-success {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #47a447;
  border-color: #47a447; }

.btn-success:hover {
  border-color: #51b451;
  background-color: #51b451; }

.btn-success:active,
.btn-success:focus {
  border-color: #3f923f;
  background-color: #3f923f; }

.btn-warning {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ed9c28;
  border-color: #ed9c28; }

.btn-warning:hover {
  border-color: #efa740;
  background-color: #efa740; }

.btn-warning:active,
.btn-warning:focus {
  border-color: #e89113;
  background-color: #e89113; }

.btn-danger {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #d2322d;
  border-color: #d2322d; }

.btn-danger:hover {
  border-color: #d64742;
  background-color: #d64742; }

.btn-danger:active,
.btn-danger:focus {
  border-color: #bd2d29;
  background-color: #bd2d29; }

.btn-info {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bc0de;
  border-color: #5bc0de; }

.btn-info:hover {
  border-color: #70c8e2;
  background-color: #70c8e2; }

.btn-info:active,
.btn-info:focus {
  border-color: #46b8da;
  background-color: #46b8da; }

.btn-dark {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #171717;
  border-color: #171717; }

.btn-dark:hover {
  border-color: #242424;
  background-color: #242424; }

.btn-dark:active,
.btn-dark:focus {
  border-color: #0a0a0a;
  background-color: #0a0a0a; }

section.highlight {
  background-color: #585858 !important;
  border-color: #333 !important; }

section.page-top h1 {
  border-bottom-color: #585858; }

section.toggle label {
  color: #585858;
  border-left-color: #585858;
  border-right-color: #585858; }

section.toggle.active > label {
  background-color: #585858;
  border-color: #585858;
  color: #FFF; }

section.page-top.custom-product {
  background-color: #333;
  border-top-color: #0081c2; }

.feature-box .feature-box-icon {
  background-color: #585858; }

.feature-box.secundary .feature-box-icon i.fa {
  color: #585858; }

.thumb-info .thumb-info-type {
  background-color: #585858; }

.thumb-info .thumb-info-action-icon {
  background-color: #585858; }

.thumb-info-social-icons a {
  background-color: #585858; }

.thumbnail .zoom {
  background-color: #585858; }

.thumbnail > * {
  text-align: center;
  margin-top: 30px;
  font-size: 13px;
  font-weignt: bold; }
  .thumbnail > *:not(h5) {
    width: 100%;
    text-align: center;
    font-size: 40px;
    color: #A5B5CA; }

#user_icon {
  color: #7FB096; }

.img-thumbnail .zoom {
  background-color: #585858; }

.inverted {
  background-color: #585858; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #0074ad; }

.tabs ul.nav-tabs a,
.tabs ul.nav-tabs a:hover {
  color: #585858; }

.tabs ul.nav-tabs a:hover,
.tabs ul.nav-tabs a:focus {
  border-top-color: #585858; }

.tabs ul.nav-tabs li.active a {
  border-top-color: #585858;
  color: #585858; }

.tabs ul.nav-tabs.nav-justified a:hover,
.tabs ul.nav-tabs.nav-justified a:focus {
  border-top-color: #585858; }

.tabs.tabs-bottom ul.nav-tabs li a:hover,
.tabs.tabs-bottom ul.nav-tabs li.active a,
.tabs.tabs-bottom ul.nav-tabs li.active a:hover,
.tabs.tabs-bottom ul.nav-tabs li.active a:focus {
  border-bottom-color: #585858; }

.tabs.tabs-vertical.tabs-left ul.nav-tabs li a:hover,
.tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a,
.tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a:hover,
.tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a:focus {
  border-left-color: #585858; }

.tabs.tabs-vertical.tabs-right ul.nav-tabs li a:hover,
.tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a,
.tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a:hover,
.tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a:focus {
  border-right-color: #585858; }

ul.list.icons li i {
  color: #585858; }

i.icon-featured {
  background-color: #585858; }

.parallax blockquote i.fa-quote-left {
  color: #585858; }

section.video blockquote i.fa-quote-left {
  color: #585858; }

.panel-group .panel-heading a {
  color: #585858; }

.panel-group.secundary .panel-heading {
  background-color: #585858 !important; }

android-content mdl-layout__content layout-mobile {
  width: 320px; }

.mdl-layout__container {
  height: auto; }

.mdl-button--accent.mdl-button--accent.mdl-button--raised, .mdl-button--accent.mdl-button--accent.mdl-button--fab {
  background-color: #414141;
  width: 100%; }

#body header {
  width: 90%;
  margin: 0 auto; }

#body #signInForm .form-group {
  width: 90%;
  margin: 0 auto; }

#body #signInForm .form-footer {
  width: 90%;
  margin: 0 auto; }
  #body #signInForm .form-footer #login-submit {
    widtH: 100%;
    margin-top: 20px;
    height: 40px; }

#body p {
  width: 90%;
  text-align: right;
  margin: 0 auto;
  margin-bottom: 30px; }

#body #signInForm.form {
  margin-bottom: 0; }

header {
  background: #ffffff;
  clear: both;
  border-top: 5px solid #ededed;
  padding: 8px 0; }
  header .mdl-layout__tab-bar {
    background-color: #414141 !important; }
  header .subTab-bar {
    background-color: white; }
    header .subTab-bar .mdl-layout__tab {
      color: #b7b7b7; }
      header .subTab-bar .mdl-layout__tab.is-active {
        color: #f48fb1 !important; }

.main_head {
  height: 100px; }
  .main_head .main_header {
    width: 100%; }
    .main_head .main_header #header_top {
      background-color: #fdeae8;
      border-bottom: 1px solid #888888;
      height: 50px;
      padding: 0;
      margin: 0;
      padding-left: 60px; }
      .main_head .main_header #header_top li {
        list-style: none;
        float: left;
        line-height: 50px;
        text-align: center;
        width: 28%; }
        .main_head .main_header #header_top li a {
          color: #0a0a0a; }
      .main_head .main_header #header_top .join_login {
        width: 38%;
        padding-left: 8%; }
      .main_head .main_header #header_top .home_icon {
        width: 8%;
        margin-right: 10px;
        float: right; }
        .main_head .main_header #header_top .home_icon .material-icons {
          vertical-align: text-top; }
      .main_head .main_header #header_top .menuLink {
        text-decoration: none;
        display: inline; }
    .main_head .main_header #header_bottom {
      background-color: rgba(253, 234, 232, 0.6);
      height: 50px;
      padding: 0;
      margin: 0;
      z-index: 2; }
      .main_head .main_header #header_bottom li {
        list-style: none;
        float: left;
        line-height: 50px;
        wertical-align: middle;
        text-align: center;
        width: 20%; }
        .main_head .main_header #header_bottom li a {
          color: #0a0a0a; }
      .main_head .main_header #header_bottom .menuLink {
        text-decoration: none;
        display: inline; }
        .main_head .main_header #header_bottom .menuLink:active {
          font-weight: bold; }

header nav ul.nav-main li a {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-left: 3px;
  margin-right: 3px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 13px; }

header nav ul.nav-main ul.dropdown-menu,
header nav ul.nav-main li.dropdown.open .dropdown-toggle,
header nav ul.nav-main li.active a,
header nav ul.nav-main li > a:hover,
header nav ul.nav-main li.dropdown:hover a {
  color: #FFF;
  background-color: #585858 !important; }

header nav ul.nav-main ul.dropdown-menu li:hover > a {
  background-color: #00aaff !important; }

header nav ul.nav-main ul.dropdown-menu {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #585858;
  border-color: #585858; }

header nav ul.nav-main ul.dropdown-menu:hover {
  border-color: #333;
  background-color: #333; }

header nav ul.nav-main ul.dropdown-menu:active,
header nav ul.nav-main ul.dropdown-menu:focus {
  border-color: #333;
  background-color: #333; }

header nav ul.nav-main li.dropdown:hover ul.dropdown-menu li > a {
  background: none !important; }

header nav ul.nav-main li.dropdown:hover ul.dropdown-menu li > a:hover {
  background: #333 !important; }

@media (min-width: 992px) {
  #header.single-menu nav ul.nav-main li.active > a {
    border-top: 5px solid #585858; }
  #header.darken-top-border {
    border-top: 5px solid #333 !important; }
  #header.colored .header-top {
    background-color: #585858; }
  #header.colored .header-top ul.nav-top li a:hover {
    background-color: #333 !important; }
  #header.flat-menu nav ul.nav-main li > a,
  #header.flat-menu nav ul.nav-main li.active > a {
    color: #666; }
  #header.flat-menu nav ul.nav-main li.active > a {
    color: #585858;
    background-color: transparent !important; }
  #header.flat-menu nav ul.nav-main li > a:hover,
  #header.flat-menu nav ul.nav-main li.dropdown:hover a {
    color: #FFF;
    background-color: #585858 !important; }
  #header nav.mega-menu ul.nav-main li.mega-menu-item ul.dropdown-menu {
    border-top-color: #585858; } }

blockquote.testimonial {
  background: #333; }

.testimonial-arrow-down {
  border-top-color: #333; }

.featured-box-secundary i.icon-featured {
  background-color: #585858; }

.featured-box-secundary h4 {
  color: #585858; }

.featured-box-secundary .box-content {
  border-top-color: #585858; }

.pricing-table .most-popular {
  border-color: #585858; }

.pricing-table .most-popular h3 {
  background-color: #585858; }

section.timeline .timeline-box.left:before,
section.timeline .timeline-box.right:before {
  background: #585858;
  box-shadow: 0 0 0 3px #ffffff, 0 0 0 6px #585858; }

ul.history li .featured-box .box-content {
  border-top-color: #585858; }

.recent-posts .date .month,
article.post .post-date .month {
  background-color: #585858; }

.recent-posts .date .day,
article.post .post-date .day {
  color: #585858; }

.slider .tp-bannertimer {
  background-color: #585858; }

.home-intro p em {
  color: #00aaff; }

.home-concept strong {
  color: #585858; }

.shop ul.products li.product .onsale {
  background-color: #585858;
  border-bottom-color: #006da3; }

.shop .star-rating span,
.shop .cart-totals tr.total .amount {
  color: #585858; }

#footer {
  background: #fff;
  color: #333;
  border-top: 1px solid #ccc;
  font-size: .9em;
  margin-top: 40px;
  padding: 40px 0 0;
  position: relative;
  clear: both; }

html.boxed .body {
  border-top-color: #585858; }

html[dir="rtl"] .tabs.tabs-vertical.tabs-left ul.nav-tabs li a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a:focus {
  border-right-color: #585858;
  border-left-color: transparent; }

html[dir="rtl"] .tabs.tabs-vertical.tabs-right ul.nav-tabs li a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a:focus {
  border-right-color: transparent;
  border-left-color: #585858; }

.navbar-brand {
  padding: 0; }

.navbar-right {
  margin-top: 20px; }

.login {
  background-color: white; }
  .login p, .login p a {
    color: #999; }

.mdl-layout__header {
  position: fixed; }
  .mdl-layout__header .mdl-layout__drawer-button {
    background: transparent;
    line-height: 40px;
    color: #585858;
    margin-left: 10px; }

header a {
  color: white; }

.small-form {
  width: 70%;
  margin: 0 auto; }

.form-footer.no-button {
  padding-bottom: 10px; }

.form-footer .mdl-button {
  margin-top: 10px;
  margin-bottom: 10px; }

main {
  width: 100%; }

.mdl-layout-title {
  color: #585858;
  font-weight: bold; }

.mdl-layout__drawer {
  background-color: #fdeae8; }
  .mdl-layout__drawer .mdl-layout-title {
    color: black; }
    .mdl-layout__drawer .mdl-layout-title * {
      color: #585858; }
  .mdl-layout__drawer .drawer_page .thumbnail {
    background-color: transparent; }
  .mdl-layout__drawer .mdl-navigation__link {
    padding: 0;
    color: #585858 !important; }
    .mdl-layout__drawer .mdl-navigation__link .nav-link-name {
      width: 100%;
      height: 100%; }
      .mdl-layout__drawer .mdl-navigation__link .nav-link-name .material-icons {
        float: right; }
    .mdl-layout__drawer .mdl-navigation__link .make-account {
      color: rgba(255, 255, 255, 0.6) !important;
      border-bottom: 1px dotted rgba(255, 255, 255, 0.4);
      width: calc(50% - 8px);
      overflow: visible;
      text-align: center; }
      .mdl-layout__drawer .mdl-navigation__link .make-account:first-child {
        float: left; }
      .mdl-layout__drawer .mdl-navigation__link .make-account:last-child {
        float: right; }

.mdl-layout__drawer {
  text-align: left;
  position: fixed;
  height: 100%; }
  .mdl-layout__drawer a {
    padding-top: 0;
    padding-bottom: 0; }
  .mdl-layout__drawer h5 {
    color: black;
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 15px;
    margin-bottom: 0;
    padding-left: 16px; }
  .mdl-layout__drawer .login_page {
    text-align: center;
    padding: 10px 0; }
  .mdl-layout__drawer .nav_login {
    background-color: black;
    padding: 10px 0;
    text-align: center; }
    .mdl-layout__drawer .nav_login .login_link {
      color: white; }
    .mdl-layout__drawer .nav_login .loggedOut {
      color: white;
      padding-top: 0; }
  .mdl-layout__drawer .nav_regist {
    padding: 10px 0;
    text-align: center; }
  .mdl-layout__drawer .nav_quick {
    background-color: black;
    padding: 10px 0;
    text-align: center; }
    .mdl-layout__drawer .nav_quick .connecting-header {
      color: white; }
  .mdl-layout__drawer mdl-navigation {
    padding-top: 0; }
  .mdl-layout__drawer .babySit-header {
    margin-top: 30px; }
  .mdl-layout__drawer .leave {
    width: 100%;
    padding-left: 16px; }

.mdl-layout__header-row .member-info {
  position: absolute;
  right: 0; }

#nav_list {
  border-top: 1px solid rgba(14, 14, 14, 0.47);
  padding-top: 40px; }

#guidance {
  padding-left: 16px;
  margin-top: 35px;
  margin-bottom: 10px;
  font-size: 16px; }

#top_login {
  padding-left: 30px; }

#sublist {
  padding: 6px 16px 6px 16px; }

.search-table td:not(last-child), .search-table th:not(last-child) {
  width: 30%; }
  .search-table td:not(last-child) .form-control, .search-table th:not(last-child) .form-control {
    margin-right: 5px; }

.search-table button {
  background-color: transparent;
  border: none; }

.search-table .form-control {
  margin-right: 5px; }

button {
  background-color: transparent;
  border: none; }

.request-title {
  text-align: center; }

.gotoEdit {
  position: absolute;
  right: 20px;
  top: 20px;
  position: absolute;
  right: 10px;
  top: 10px; }
  .gotoEdit * {
    display: block;
    margin-bottom: 5px; }

.label-invalid {
  position: absolute;
  left: 20px;
  top: 20px;
  display: block; }

.card-moreOption {
  position: absolute;
  right: 20px;
  top: 20px;
  border: none;
  background: none; }

.selectInCell {
  width: 100%;
  height: 100%;
  background: url("/assets/img/icon/ic_keyboard_arrow_down_black_24dp.png") no-repeat;
  background-size: contain;
  background-position: right center; }

.simpleRequest-list {
  background-color: #fff4ea; }
  .simpleRequest-list li {
    position: relative;
    border-radius: 15px; }
  .simpleRequest-list h4 {
    text-align: center; }
  .simpleRequest-list tr td:last-child:not(.memo) {
    padding-left: 10px; }

.btn-href {
  width: 100%;
  background-color: #ff4081 !important; }

.href-call {
  color: #f48fb1;
  text-decoration: underline; }

.created {
  text-align: center;
  padding: 0 20px;
  background-color: white; }
  .created h1 {
    color: #f48fb1; }
  .created .start {
    width: 100%;
    border: none;
    border-radius: 10px;
    text-align: center;
    color: white !important;
    font-weight: bold; }

.alertLayer {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); }
  .alertLayer .alertBox {
    width: 280px;
    margin: 0 auto;
    background-color: white;
    border: none;
    border-radius: 10px;
    position: relative;
    text-align: center;
    padding: 10px; }
    .alertLayer .alertBox .btn-close {
      position: absolute;
      right: 0;
      top: -24px;
      color: white; }

.stime-table {
  width: 100%; }
  .stime-table td {
    padding-left: 1px;
    padding-right: 1px; }
  .stime-table select {
    margin: 0 1px; }

.loader {
  color: rgba(244, 143, 177, 0.7);
  margin: 80px auto;
  position: relative;
  transform: translateZ(0);
  animation-delay: -0.16s; }
  .loader, .loader:before, .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: load 1.8s infinite ease-out; }
  .loader:before, .loader:after {
    content: "";
    position: absolute;
    top: 0; }
  .loader:before {
    left: -3.5em;
    animation-delay: -0.32s; }
  .loader:after {
    left: 3.5em; }

@keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    box-shadow: 0 2.5em 0 0; } }

.img-circle {
  background-color: rgba(244, 143, 177, 0.5); }

.writer-profile {
  width: 100%; }
  .writer-profile .thumbnail {
    background-color: transparent; }
  .writer-profile .img-circle {
    background-color: white; }
  .writer-profile > * {
    display: inline; }
  .writer-profile h3 {
    color: white; }

.label-connected {
  background-color: #ffa8a5 !important; }

.gender {
  color: #ff7db4; }

.gender, .text-label {
  margin-left: 5px; }

.info-title, .info-title > *:not(h4) {
  width: 29%;
  text-align: left;
  line-height: 34px; }

.info-content {
  width: 70%;
  display: inline;
  float: right;
  text-align: center;
  text-align-last: center; }
  .info-content input {
    text-align: center; }
  .info-content select {
    text-align: center;
    text-align-last: center; }

.android-content {
  overflow: visible;
  height: auto;
  position: relative; }
  .android-content.layout-mobile {
    min-height: calc(100% - 52px);
    margin-top: 150px; }
    .android-content.layout-mobile.layout-hasSubmenu {
      min-height: calc(100% - 104px);
      margin-top: 100px; }
  .android-content.layout-mobile-details {
    min-height: calc(100% - 56px);
    margin-top: 56px; }
  .android-content .container.login, .android-content main, .android-content .profile.detail {
    min-height: 100%;
    overflow: visible; }

.alert-danger {
  display: none; }

label.error {
  color: #e91e5a; }

.container.login {
  height: 430px;
  padding-top: 30px; }

.container.login + .mdl-mega-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }

.card-list {
  padding: 0;
  width: 100%; }

.list + .mdl-mega-footer, .list + footer {
  display: none; }

.myRequest {
  margin-top: 48px; }

.btn-reaction {
  background: none;
  border: none; }
  .btn-reaction.reaction-active {
    color: #ff88a7; }

.list-title {
  background-color: #3B4044; }
  .list-title h4 {
    color: white;
    text-align: center; }

.detail .demo-card-wide > .mdl-card__title {
  height: 230px !important; }

/*
.info-wrap {

    height: 34px;
    > * {
        line-height: 34px;
    }
}
*/
.option {
  font-size: 0.8em;
  color: #f1a7bb; }

header {
  border: none; }

h1.mdl-layout-title {
  display: flex;
  flex-direction: row; }

.profile-name {
  line-height: 56px;
  text-align: center; }
  .profile-name a {
    font-size: 18px;
    font-weight: bold; }

.account {
  background-color: #fff4ea;
  padding-top: 40px;
  padding-bottom: 40px; }
  .account .is-focused input {
    border: none; }
  .account #imoForm {
    background-color: white;
    border: 1px solid #858585;
    border-radius: 15px;
    width: 90%;
    margin: 0 auto;
    padding: 20px; }
    .account #imoForm .form-group.have-help {
      padding-bottom: 5px; }
    .account #imoForm .form-group label {
      font-size: 14px; }
    .account #imoForm .form-group input {
      background-image: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.26);
      text-align: center; }
    .account #imoForm .form-group .mdl-grid {
      width: 100%;
      display: block; }
      .account #imoForm .form-group .mdl-grid .check_box {
        float: right; }
        .account #imoForm .form-group .mdl-grid .check_box #check_title {
          margin-right: 10px;
          font-size: 14px;
          margin-bottom: 0; }
        .account #imoForm .form-group .mdl-grid .check_box .mdl-radio__label {
          font-size: 13px; }
    .account #imoForm p {
      display: block;
      text-align: right;
      color: #f1a7bb;
      font-size: 13px; }
    .account #imoForm .have-switch {
      height: 48px; }
    .account #imoForm .switch-title {
      width: auto !important;
      float: left;
      margin-bottom: 0 !important; }
    .account #imoForm .mdl-switch {
      width: auto !important;
      float: right;
      margin-top: 14px;
      margin-right: 10px; }
    .account #imoForm .btn {
      font-weight: bold; }
    .account #imoForm .have-help {
      padding-bottom: 0; }
      .account #imoForm .have-help .mdl-textfield__label:after {
        bottom: 20px !important; }
    .account #imoForm .mdl-textfield__label:after {
      bottom: 12px;
      background-color: #f48fb1;
      margin-left: 10px;
      margin-right: 10px; }
    .account #imoForm .btn--flat {
      width: 100%;
      color: white;
      font-weight: bold; }
      .account #imoForm .btn--flat.btn--save {
        background-color: #ff6a89; }
      .account #imoForm .btn--flat.btn--list {
        background-color: grey; }
    .account #imoForm .profile-img {
      width: 56px;
      height: 56px;
      margin: 0 auto;
      background-color: transparent; }
      .account #imoForm .profile-img #drawer_icon {
        color: black;
        margin-top: 15px; }
    .account #imoForm .date-wrapper {
      width: 100%; }
      .account #imoForm .date-wrapper input {
        display: inline;
        width: 80%; }
    .account #imoForm .radios-inline > label {
      width: 48%;
      text-align: left; }
    .account #imoForm h4 {
      margin-left: 20px;
      color: #585858; }
      .account #imoForm h4 .account-help {
        font-size: 1rem !important;
        color: #aaa; }
    .account #imoForm .req {
      color: #dc6089 !important; }
    .account #imoForm .using_terms .term_title {
      text-align: center; }
    .account #imoForm .using_terms .term_text {
      max-height: 120px;
      overflow: scroll;
      font-size: 13px;
      white-space: normal;
      word-wrap: normal;
      border: 1px solid lightgrey; }
      .account #imoForm .using_terms .term_text p {
        max-width: 100%;
        text-align: left;
        color: gray; }
    .account #imoForm .using_terms .agree_check {
      align-items: center; }
      .account #imoForm .using_terms .agree_check p {
        font-size: 13px;
        margin-top: 8px;
        margin-bottom: 8px;
        color: #A5B5CA; }
      .account #imoForm .using_terms .agree_check .agree_check_box {
        float: right; }
        .account #imoForm .using_terms .agree_check .agree_check_box input {
          margin-right: 5px; }
        .account #imoForm .using_terms .agree_check .agree_check_box .agree_btn {
          margin-right: 10px; }
        .account #imoForm .using_terms .agree_check .agree_check_box .cancle_btn {
          color: #adadad; }
  .account #imoForm,
  .account #userForm {
    background-color: white;
    border: 1px solid #858585;
    border-radius: 15px;
    width: 90%;
    margin: 0 auto;
    padding: 20px; }
    .account #imoForm .form-group.have-help,
    .account #userForm .form-group.have-help {
      padding-bottom: 5px; }
    .account #imoForm .form-group label,
    .account #userForm .form-group label {
      font-size: 14px; }
    .account #imoForm .form-group input,
    .account #userForm .form-group input {
      background-image: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.26);
      text-align: center; }
    .account #imoForm .form-group .mdl-grid,
    .account #userForm .form-group .mdl-grid {
      width: 100%;
      display: block; }
      .account #imoForm .form-group .mdl-grid .check_box,
      .account #userForm .form-group .mdl-grid .check_box {
        float: right; }
        .account #imoForm .form-group .mdl-grid .check_box #check_title,
        .account #userForm .form-group .mdl-grid .check_box #check_title {
          margin-right: 10px;
          font-size: 14px;
          margin-bottom: 0; }
        .account #imoForm .form-group .mdl-grid .check_box .mdl-radio__label,
        .account #userForm .form-group .mdl-grid .check_box .mdl-radio__label {
          font-size: 13px; }
    .account #imoForm p,
    .account #userForm p {
      display: block;
      text-align: right;
      color: #f1a7bb;
      font-size: 13px; }
    .account #imoForm .have-switch,
    .account #userForm .have-switch {
      height: 48px; }
    .account #imoForm .switch-title,
    .account #userForm .switch-title {
      width: auto !important;
      float: left;
      margin-bottom: 0 !important; }
    .account #imoForm .mdl-switch,
    .account #userForm .mdl-switch {
      width: auto !important;
      float: right;
      margin-top: 14px;
      margin-right: 10px; }
    .account #imoForm .btn,
    .account #userForm .btn {
      font-weight: bold; }
    .account #imoForm .have-help,
    .account #userForm .have-help {
      padding-bottom: 0; }
      .account #imoForm .have-help .mdl-textfield__label:after,
      .account #userForm .have-help .mdl-textfield__label:after {
        bottom: 20px !important; }
    .account #imoForm .mdl-textfield__label:after,
    .account #userForm .mdl-textfield__label:after {
      bottom: 12px;
      background-color: #f48fb1;
      margin-left: 10px;
      margin-right: 10px; }
    .account #imoForm .btn--flat,
    .account #userForm .btn--flat {
      width: 100%;
      color: white;
      font-weight: bold; }
      .account #imoForm .btn--flat.btn--save,
      .account #userForm .btn--flat.btn--save {
        background-color: #ff6a89; }
      .account #imoForm .btn--flat.btn--list,
      .account #userForm .btn--flat.btn--list {
        background-color: grey; }
    .account #imoForm .profile-img,
    .account #userForm .profile-img {
      width: 56px;
      height: 56px;
      margin: 0 auto;
      background-color: transparent; }
      .account #imoForm .profile-img #drawer_icon,
      .account #userForm .profile-img #drawer_icon {
        color: black;
        margin-top: 15px; }
    .account #imoForm .date-wrapper,
    .account #userForm .date-wrapper {
      width: 100%; }
      .account #imoForm .date-wrapper input,
      .account #userForm .date-wrapper input {
        display: inline;
        width: 80%; }
    .account #imoForm .radios-inline > label,
    .account #userForm .radios-inline > label {
      width: 48%;
      text-align: left; }
    .account #imoForm h4,
    .account #userForm h4 {
      margin-left: 20px;
      color: #585858; }
      .account #imoForm h4 .account-help,
      .account #userForm h4 .account-help {
        font-size: 1rem !important;
        color: #aaa; }
    .account #imoForm .req,
    .account #userForm .req {
      color: #dc6089 !important; }
    .account #imoForm .using_terms .term_title,
    .account #userForm .using_terms .term_title {
      text-align: center; }
    .account #imoForm .using_terms .term_text,
    .account #userForm .using_terms .term_text {
      max-height: 120px;
      overflow: scroll;
      font-size: 13px;
      white-space: normal;
      word-wrap: normal;
      border: 1px solid lightgrey; }
      .account #imoForm .using_terms .term_text p,
      .account #userForm .using_terms .term_text p {
        max-width: 100%;
        text-align: left;
        color: gray; }
    .account #imoForm .using_terms .agree_check,
    .account #userForm .using_terms .agree_check {
      align-items: center; }
      .account #imoForm .using_terms .agree_check p,
      .account #userForm .using_terms .agree_check p {
        font-size: 13px;
        margin-top: 8px;
        margin-bottom: 8px;
        color: #A5B5CA; }
      .account #imoForm .using_terms .agree_check .agree_check_box,
      .account #userForm .using_terms .agree_check .agree_check_box {
        float: right; }
        .account #imoForm .using_terms .agree_check .agree_check_box input,
        .account #userForm .using_terms .agree_check .agree_check_box input {
          margin-right: 5px; }
        .account #imoForm .using_terms .agree_check .agree_check_box .agree_btn,
        .account #userForm .using_terms .agree_check .agree_check_box .agree_btn {
          margin-right: 10px; }
        .account #imoForm .using_terms .agree_check .agree_check_box .cancle_btn,
        .account #userForm .using_terms .agree_check .agree_check_box .cancle_btn {
          color: #adadad; }

.card-radio {
  position: relative; }
  .card-radio .mdl-radio {
    position: absolute;
    top: 20px;
    right: 20px; }

@media screen and (max-width: 839px) {
  .mdl-cell--4-col.mdl-cell--3-col-phone, .mdl-cell--4-col.mdl-cell--3-col-tablet {
    width: calc(33% - 16px) !important; } }

.urgent-request h5 {
  text-align: center; }

.announce-empty {
  text-align: center;
  font-size: 20px;
  padding-top: 40px; }

.listName {
  text-align: center;
  position: relative; }
  .listName button {
    position: absolute;
    right: 16px; }

li.failed {
  opacity: 0.7; }

.test label {
  width: 50px;
  height: 50px;
  border: 1px solid black; }

.test input {
  display: none; }

.form-control {
  text-align: center;
  text-align-last: center; }

.inputs-box {
  width: 100%; }

.form--edit {
  padding-left: 20px;
  padding-right: 20px; }

.list-finder {
  width: 100%; }
  .list-finder tr:first-child table th {
    border-bottom: 1px solid white; }
  .list-finder tr:first-child table td {
    border-bottom: 1px solid #eeeeee; }
  .list-finder td {
    width: 50%; }
    .list-finder td table {
      width: 100%; }
    .list-finder td th {
      width: 25%;
      background-color: #e91e63; }
      .list-finder td th label {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white;
        text-align: center; }
    .list-finder td td {
      background-color: white;
      padding: 5px 2px; }
      .list-finder td td input, .list-finder td td select {
        width: 100%;
        border: none;
        text-align: center;
        text-align-last: center; }

.material-icons {
  vertical-align: text-bottom; }

#findAddressButton {
  float: left; }

.wrap-addr1 {
  height: auto;
  overflow: hidden; }

#fileName, #addr1Text {
  text-overflow: ellipsis;
  padding: 0 10px;
  display: inline; }

#addr1Text {
  line-height: 36px; }

.content-photo {
  padding-right: 24px; }

#photoField, #addr1Field {
  display: none; }

.photoBox {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  padding: 5px 0; }

.remove-file {
  border: none;
  background-color: transparent;
  display: none;
  color: #ccc;
  float: right; }

.checkbox-table {
  width: 100%;
  margin-top: 8px; }
  .checkbox-table .mdl-checkbox__label {
    margin-left: -5px; }

.detail .mdl-card__supporting-text {
  padding-top: 28px; }

.mdl-card__supporting-text h4 {
  margin-top: 0; }

.mdl-card__supporting-text .form-footer {
  margin-bottom: 0; }

.mdl-card__supporting-text .request-title {
  text-align: center; }

.form-header {
  padding: 8px; }
  .form-header .btn-filter {
    width: 100%; }

#scroll-top {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 15px;
  bottom: 15px;
  background-color: #fdeae8;
  z-index: 10;
  padding: 0 !important;
  text-align: center;
  vertical-align: middle; }
  #scroll-top i {
    text-align: center;
    vertical-align: middle;
    line-height: 40px; }

.filter-header {
  width: 100%;
  text-align: center; }

.mobileCall {
  text-decoration: underline !important; }

.filter-layer {
  display: none;
  width: 100%;
  height: calc(100% - 56px);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 200;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.5); }
  .filter-layer .mobile-href a {
    text-align: center; }
  .filter-layer#findAddrLayer {
    height: calc(100% - 56px); }
    .filter-layer#findAddrLayer .filter-card {
      width: 300px;
      height: 400px; }
  .filter-layer #findAddrCard {
    width: 300px;
    height: 400px; }
  .filter-layer#findAddrLayer:not(.low-layer) {
    height: calc(100% - 56px); }
  .filter-layer .filterFormWrap {
    vertical-align: bottom; }
  .filter-layer h4 {
    text-align: center; }
  .filter-layer .filter-card {
    position: relative;
    background-color: white;
    display: inline-block;
    vertical-align: bottom; }
    .filter-layer .filter-card:not(.options-card) {
      width: 100%; }
    .filter-layer .filter-card.options-card {
      width: 50%;
      margin: 0 25%; }
      .filter-layer .filter-card.options-card button.card-option {
        border: 0;
        background: none; }
      .filter-layer .filter-card.options-card .card-option {
        width: 100%;
        display: block;
        padding: 10px 0;
        border-bottom: 1px solid #777;
        text-align: center;
        color: #777; }
    .filter-layer .filter-card.delete-card {
      display: none; }

.stime-table td {
  text-align: center; }

.inline-header .text-label {
  float: right; }

.inline-header .label {
  vertical-align: text-top; }

.thumbnailAndName {
  width: 100%;
  padding-top: 0; }
  .thumbnailAndName > * {
    display: inline; }
  .thumbnailAndName .inline-header {
    vertical-align: middle;
    line-height: 58px; }
    .thumbnailAndName .inline-header * {
      vertical-align: middle; }

.form--list .mdl-grid {
  padding: 8px 0; }

.form--list .mdl-cell--3-col-phone {
  width: 70% !important;
  margin: 8px 0; }

.form--list .text i {
  vertical-align: -5.5px; }

.form--list .text div {
  display: inline; }

.mdl-card__title {
  background-color: #AAB6C4;
  position: relative; }
  .mdl-card__title.bulletin-title {
    display: block; }
  .mdl-card__title .mdl-card__title-text, .mdl-card__title .writer-profile h3 {
    text-shadow: #ff5a5a 0px 0px 10px 10px; }
  .mdl-card__title .button-like {
    position: absolute;
    right: 5%;
    bottom: -28px;
    background-color: #ffd54f; }

.detail-table th {
  text-align: center; }

.table.table-memberDetail tr:first-child * {
  border: none; }

.table.table-memberDetail td {
  word-break: break-all; }

#imoListForm {
  background-color: rgba(242, 243, 242, 0.3); }
  #imoListForm .search_box {
    width: calc(100% - 16px);
    margin: 0 auto;
    padding-top: 30px; }
    #imoListForm .search_box .customSelect {
      padding-left: 10px;
      height: 47px;
      width: 49%;
      border: 2px solid #A5B5CA;
      background-color: transparent;
      display: inline-block;
      float: left;
      border-radius: 10px 10px 0px 0px; }
      #imoListForm .search_box .customSelect #addr1Filter {
        border: none;
        width: 110px;
        margin-top: 3px;
        margin-right: 0;
        height: 35px;
        display: inline;
        float: right; }
      #imoListForm .search_box .customSelect .info-title {
        font-weight: bold;
        font-size: 14px;
        color: #3e3737;
        line-height: 40px; }
    #imoListForm .search_box .customSelect2 {
      padding-left: 10px;
      height: 47px;
      width: 49%;
      border: 2px solid #A5B5CA;
      background-color: transparent;
      display: inline-block;
      float: right;
      border-radius: 10px 10px 0px 0px; }
      #imoListForm .search_box .customSelect2 #fieldFilter {
        border: none;
        width: 70%;
        margin-top: 3px;
        margin-right: 0;
        height: 35px;
        display: inline;
        float: right; }
      #imoListForm .search_box .customSelect2 .info-title {
        font-weight: bold;
        font-size: 14px;
        color: #585858;
        line-height: 40px;
        width: 27px; }
    #imoListForm .search_box #list_search_btn {
      display: block;
      background-color: #A5B5CA;
      height: 45px;
      color: white;
      width: 100%;
      border-radius: 0 0 10px 10px;
      margin-bottom: 10px; }
      #imoListForm .search_box #list_search_btn .search_btn {
        width: 100%;
        text-align: center;
        line-height: 40px; }
  #imoListForm .mdl-shadow--4dp {
    border-radius: 15px;
    border: 1px solid #7a7a7a; }

#userListForm {
  background-color: rgba(242, 243, 242, 0.3); }
  #userListForm .search_box {
    width: calc(100% - 16px);
    margin: 0 auto;
    padding-top: 30px; }
    #userListForm .search_box .customSelect {
      padding-left: 10px;
      height: 47px;
      width: 49%;
      border: 2px solid #7FB096;
      background-color: transparent;
      display: inline-block;
      float: left;
      border-radius: 10px 10px 0px 0px; }
      #userListForm .search_box .customSelect #addr1Filter {
        border: none;
        width: 110px;
        margin-top: 3px;
        margin-right: 0;
        height: 35px;
        display: inline;
        float: right; }
      #userListForm .search_box .customSelect .info-title {
        font-weight: bold;
        font-size: 14px;
        color: #3e3737;
        line-height: 40px; }
    #userListForm .search_box .customSelect2 {
      padding-left: 10px;
      height: 47px;
      width: 49%;
      border: 2px solid  #7FB096;
      background-color: transparent;
      display: inline-block;
      float: right;
      border-radius: 10px 10px 0px 0px; }
      #userListForm .search_box .customSelect2 #fieldFilter {
        border: none;
        width: 70%;
        margin-top: 3px;
        margin-right: 0;
        height: 35px;
        display: inline;
        float: right; }
      #userListForm .search_box .customSelect2 .info-title {
        font-weight: bold;
        font-size: 14px;
        color: #585858;
        line-height: 40px;
        width: 27px; }
    #userListForm .search_box #list_search_btn {
      display: block;
      background-color: #7FB096;
      height: 45px;
      color: white;
      width: 100%;
      border-radius: 0 0 10px 10px;
      margin-bottom: 10px; }
      #userListForm .search_box #list_search_btn .search_btn {
        width: 100%;
        text-align: center;
        line-height: 40px; }
  #userListForm .mdl-shadow--4dp {
    border-radius: 15px;
    border: 1px solid #7a7a7a; }

#simpleRequestList {
  background-color: #fafafa;
  padding: 8px;
  padding-top: 0; }
  #simpleRequestList .mdl-shadow--4dp {
    border-radius: 15px;
    border: 1px solid #7a7a7a; }
  #simpleRequestList h4 {
    color: #7FB096; }
  #simpleRequestList #request_icon {
    color: #ffa8a5; }
  #simpleRequestList .mdl-grid {
    padding: 0; }
  #simpleRequestList .cell_box {
    width: 70%; }
    #simpleRequestList .cell_box .text-label {
      float: right; }
    #simpleRequestList .cell_box .list-table {
      margin: 8px 0; }
      #simpleRequestList .cell_box .list-table td {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 13px; }
      #simpleRequestList .cell_box .list-table th {
        font-size: 12px; }
      #simpleRequestList .cell_box .list-table #request_text {
        color: #e4a6a6;
        font-weight: bold;
        font-size: 14px; }

.list-table {
  table-layout: fixed;
  width: 100%; }
  .list-table th {
    width: 50px;
    text-align: center; }
  .list-table tr {
    border-bottom: 1px solid gainsboro;
    font-size: 12px; }
  .list-table td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 13px; }
  .list-table #intro_text {
    color: #A5B5CA;
    font-weight: bold;
    font-size: 14px; }
  .list-table #user_text {
    color: #8BC2A5;
    font-weight: bold;
    font-size: 14px; }

.help-block {
  color: #f1a7bb;
  margin-bottom: 0;
  margin-top: 0;
  word-break: keep-all;
  line-height: 18px;
  font-weight: 200;
  letter-spacing: -1px;
  text-align: center; }

.table-radio {
  width: 100%; }
  .table-radio .mdl-radio__label {
    margin-left: -5px; }

.appear-by-term {
  display: none; }
  .appear-by-term.info-wrap {
    margin-top: 24px; }
  .appear-by-term .info-title {
    width: 60%; }
    .appear-by-term .info-title h4 {
      margin-top: 0;
      margin-bottom: 0; }
  .appear-by-term .info-content {
    width: 35%; }

.profile #user_card_title {
  background-color: #7FB096; }

.profile .user_memo {
  margin: 50px 0;
  padding: 0 30px;
  color: #7FB096;
  font-size: 1rem; }

.profile .imo_memo {
  margin: 50px 0;
  padding: 0 30px;
  color: #90a1b5;
  font-size: 1rem; }

.profile .request_memo {
  margin: 50px 0;
  padding: 0 30px;
  color: #e4a6a6;
  font-size: 1rem; }

.profile table:not(.review-table):not(.list-table) {
  width: 50%;
  margin: 0 auto 40px;
  table-layout: fixed; }
  .profile table:not(.review-table):not(.list-table) tr {
    color: rgba(0, 0, 0, 0.54); }
    .profile table:not(.review-table):not(.list-table) tr td {
      text-align: center;
      color: rgba(0, 0, 0, 0.54);
      padding: 3px;
      border-bottom: 1px solid #c7c7c7;
      font-size: 12px;
      font-weight: 600; }
      .profile table:not(.review-table):not(.list-table) tr td a {
        color: #e6b0b0; }
      .profile table:not(.review-table):not(.list-table) tr td .material-icons {
        font-size: 15px; }

.mdl-button--accent {
  color: white !important;
  font-weight: bold; }

.control-label {
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.5rem; }

h3.formHeader {
  border-bottom: 2px solid #333; }
  h3.formHeader .header-creDate {
    display: inline;
    float: right;
    font-size: 50%; }

.detailbody {
  border-bottom: 1px solid #333;
  margin-bottom: 30px; }

.whole-background {
  background-color: white; }

.addCommentForm {
  border: 1px dashed #999;
  border-radius: 5px;
  padding: 10px; }

ul.answers {
  border-radius: 5px;
  background-color: #ccc; }

.table-searching-form th {
  text-align: center; }

.table-search-btn {
  line-height: 140px; }

.desktop-body {
  margin: 0 200px; }

.addButton {
  position: fixed;
  left: 16px;
  bottom: 20px;
  background-color: #e91e5a !important;
  color: white;
  z-index: 10; }

.wrapToRight {
  padding-right: 16px;
  height: 22px;
  position: relative; }
  .wrapToRight input.hrefWithoutButton {
    background: none;
    border: none;
    position: absolute;
    right: 16px; }

.textarea-paragraph {
  text-align: left;
  text-align-last: left; }

.rating-table th {
  text-align: left !important; }

.review-table {
  width: 100%; }

.review-rate-wrap {
  margin-bottom: 16px; }

.rate-star {
  color: #999;
  vertical-align: middle;
  position: relative; }
  .rate-star.rate-in-list {
    width: 100%;
    display: flex; }
    .rate-star.rate-in-list .show_star_rate {
      display: flex; }
  .rate-star .show_star_rate {
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    color: #ff88a7; }
  .rate-star .on {
    color: #ff88a7; }
  .rate-star input[name=grade] {
    display: none; }

.white-background {
  background-color: white; }

.form--edit {
  background-color: white; }
  .form--edit table {
    width: 100%; }
    .form--edit table th, .form--edit table td {
      text-align: center; }

.content-area {
  min-height: 100px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.26); }

@media screen and (max-width: 479px) {
  .mypage form {
    padding: 0; }
    .mypage form .mdl-card {
      margin: 8px auto; } }

@media screen and (max-width: 479px) {
  .mypage form {
    padding: 0; }
    .mypage form .mdl-card {
      margin: 8px auto; } }

.mypage .form-footer {
  width: 100%;
  margin: 0 20px; }

.mypage .info-title {
  width: 27%; }

.mypage .info-content {
  width: 70%; }

.post {
  background-color: #fff4ea;
  padding-bottom: 30px; }
  .post .editForm-title {
    text-align: center;
    color: #343434;
    font-size: 30px; }
    .post .editForm-title .material-icons {
      vertical-align: middle; }
  .post .hrefWithoutButton {
    text-decoration: none;
    margin-left: 5%;
    color: #a5a9af; }
    .post .hrefWithoutButton .material-icons {
      font-size: 20px; }
  .post #simpleRequestForm {
    background-color: white;
    border: 1px solid #858585;
    border-radius: 15px;
    width: 90%;
    margin: 0 auto;
    padding: 20px; }
    .post #simpleRequestForm h5 {
      margin: 20px 0 20px 0;
      color: #343434;
      background-color: #e6e6e6;
      font-size: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 5px; }
    .post #simpleRequestForm .form-group .radio-box {
      width: 100%;
      margin: 0 auto; }
      .post #simpleRequestForm .form-group .radio-box .mdl-radio__label {
        margin-left: -3px;
        font-size: 14px; }
    .post #simpleRequestForm .form-group #payField {
      width: 50%;
      margin: 0 auto; }
    .post #simpleRequestForm #simpleRequest-submit {
      width: 100%;
      background-color: #343434;
      color: white; }

@media screen and (max-width: 534px) {
  .info-title.longer {
    width: 45%; }
  .info-content.shorter {
    width: 53%; } }

@media screen and (max-width: 369px) {
  .info-title.long {
    width: 35%; }
  .info-content.short {
    width: 60%; } }

@media screen and (max-width: 345px) {
  .info-title.short {
    width: 20%; }
  .info-content.long {
    width: 75%; } }

#memberTerm {
  margin: 20px;
  text-align: center;
  font-weight: bold; }

#payPrice {
  font-size: 20px;
  font-weight: bold;
  text-align: center; }

.mdl-layout__header {
  height: 56px; }
  .mdl-layout__header.hasSubmenu {
    height: 148px; }
  .mdl-layout__header.submenu {
    height: 100px; }

body.home {
  margin: auto;
  width: 100%; }
  body.home .mdl-layout--fixed-header > .mdl-layout__header {
    background: transparent;
    box-shadow: none; }
    body.home .mdl-layout--fixed-header > .mdl-layout__header .mdl-layout__drawer-button {
      color: #000; }
  body.home .main {
    height: 1000px;
    width: 100%;
    background-image: url(/assets/img/home/6____.jpg);
    background-repeat: no-repeat;
    background-size: cover; }
    body.home .main #title {
      padding-top: 120px;
      text-align: center; }
      body.home .main #title #subtitle {
        font-size: 12px;
        font-style: italic;
        margin-bottom: 5px;
        color: #3e3737; }
      body.home .main #title #imo-ko {
        color: #3e3737;
        font-family: "나눔명조";
        font-weight: 700; }
      body.home .main #title #imonet {
        margin-top: 0 !important;
        font-family: "나눔명조";
        color: #fdeae8;
        font-weight: 400; }
      body.home .main #title table {
        width: 90%;
        margin: 0 auto;
        border: 2px solid #3e3737; }
        body.home .main #title table .finding_map {
          width: 40%;
          padding-left: 10px;
          padding-right: 10px;
          height: 150px;
          background-color: rgba(89, 89, 89, 0.6);
          color: white; }
          body.home .main #title table .finding_map .material-icons {
            font-size: 30px;
            margin-bottom: 20px; }
          body.home .main #title table .finding_map #finding_imo {
            font-size: 18px; }
        body.home .main #title table .urgent_btn {
          background-color: rgba(255, 255, 255, 0.6);
          border: 1px dotted #6b5f5f; }
  body.home .mobile_list {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 30px; }
    body.home .mobile_list .search_box {
      /*style the arrow inside the select element:*/
      /*point the arrow upwards when the select box is open (active):*/
      /*style the items (options), including the selected item:*/
      /*style items (options):*/
      /*hide the items when the select box is closed:*/ }
      body.home .mobile_list .search_box .custom-select {
        position: relative;
        border-radius: 3px;
        background: #3e3737;
        width: 30%;
        padding: 0; }
        body.home .mobile_list .search_box .custom-select select {
          display: none;
          /*hide original SELECT element:*/ }
      body.home .mobile_list .search_box .select-selected {
        background-color: #3e3737; }
      body.home .mobile_list .search_box .select-selected:after {
        position: absolute;
        content: "";
        top: 14px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #fff transparent transparent transparent; }
      body.home .mobile_list .search_box .select-selected.select-arrow-active:after {
        border-color: transparent transparent #fff transparent;
        top: 7px; }
      body.home .mobile_list .search_box .select-items div, body.home .mobile_list .search_box .select-selected {
        color: #ffffff;
        padding: 5px;
        border: 1px solid transparent;
        border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
        cursor: pointer;
        user-select: none; }
      body.home .mobile_list .search_box .select-items {
        position: absolute;
        background-color: #3e3737;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99; }
      body.home .mobile_list .search_box .select-hide {
        display: none; }
      body.home .mobile_list .search_box .select-items div:hover {
        background-color: rgba(255, 255, 255, 0.3); }
      body.home .mobile_list .search_box .search_btn {
        width: 20%;
        float: right;
        height: 34px;
        border: 1px solid #3e3737;
        border-radius: 3px; }
    body.home .mobile_list .main_imo_list {
      max-width: 100%;
      margin: 0 auto;
      overflow: hidden;
      padding-top: 10px; }
      body.home .mobile_list .main_imo_list .imo_list {
        height: 120px;
        border-radius: 5px;
        border: 1px solid black;
        box-shadow: 0 2px 5px grey;
        margin-bottom: 10px;
        padding-top: 10px;
        padding-bottom: 20px; }
        body.home .mobile_list .main_imo_list .imo_list .imo_info {
          display: inline-block;
          text-align: center;
          width: 20%;
          float: left;
          margin: 7px 2px 0 5px; }
          body.home .mobile_list .main_imo_list .imo_list .imo_info #img {
            width: 60px;
            height: 60px; }
            body.home .mobile_list .main_imo_list .imo_list .imo_info #img p {
              margin-top: 15px;
              font-size: 12px; }
          body.home .mobile_list .main_imo_list .imo_list .imo_info .material-icons {
            width: 100%;
            text-align: center;
            color: #A5B5CA;
            font-size: 40px;
            padding-bottom: 10px; }
        body.home .mobile_list .main_imo_list .imo_list .list_desc {
          display: inline-block;
          width: 65%;
          margin-top: 5px; }
          body.home .mobile_list .main_imo_list .imo_list .list_desc p {
            height: 23px;
            margin: 0;
            font-size: 12px;
            overflow: hidden;
            border-bottom: 1px solid black; }
          body.home .mobile_list .main_imo_list .imo_list .list_desc .imo_desc {
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            letter-spacing: -1px;
            line-height: 19px;
            font-size: 13px;
            border: none;
            color: #88a0bf;
            height: 73px; }
        body.home .mobile_list .main_imo_list .imo_list .like_check {
          display: inline-block;
          float: right;
          width: 30px;
          height: 75px;
          line-height: 80px; }
      body.home .mobile_list .main_imo_list .imo_list:nth-child(even) {
        background-color: rgba(130, 130, 130, 0.2); }
      body.home .mobile_list .main_imo_list .imo_list:nth-child(odd) {
        background-color: rgba(245, 245, 245, 0.7); }
  body.home #catchphrase {
    margin-top: 16px;
    font-size: 17px;
    font-family: "나눔바른펜";
    font-weight: 700; }
  body.home .conA .conA-1 {
    color: white;
    height: 234px;
    width: 100%;
    background-image: url(/assets/img/home/2.jpg);
    background-repeat: no-repeat;
    background-position: center; }
    body.home .conA .conA-1 .workingmom {
      width: 360px;
      margin: 0 auto;
      text-align: left;
      padding-top: 120px;
      padding-left: 10px;
      font-size: 35px;
      font-family: "나눔명조"; }
    body.home .conA .conA-1 .safe {
      width: 360px;
      margin: 0 auto;
      text-align: left;
      font-size: 20px;
      padding-left: 10px;
      font-family: "나눔명조"; }
    body.home .conA .conA-1 .readmore {
      float: left;
      font-style: italic;
      font-size: 13px; }
  body.home .conA .conA-2 {
    height: 260px;
    width: 100%;
    background-image: url(/assets/img/home/3.jpg);
    background-repeat: no-repeat;
    background-position: center; }
    body.home .conA .conA-2 .babysitter {
      width: 360px;
      margin: 0 auto;
      padding-top: 100px;
      text-align: center; }
    body.home .conA .conA-2 .project {
      font-size: 30px;
      font-style: italic;
      font-family: "나눔명조";
      font-weight: 500;
      text-align: center; }
  @media (min-width: 768px) {
    body.home .main {
      background-image: url(/assets/img/home/6____.jpg);
      background-repeat: no-repeat;
      background-position: center; } }
  body.home .qnabox #qna-box {
    height: 230px; }
    body.home .qnabox #qna-box .qna-ko {
      width: 360px;
      margin: 0 auto;
      padding-top: 85px;
      color: black;
      font-family: "나눔명조";
      text-align: center; }
    body.home .qnabox #qna-box .qna {
      margin-top: 0;
      margin-bottom: 70px;
      color: black;
      text-align: center; }
  body.home .conB .conB-1 {
    height: 250px;
    width: 100%;
    margin: 0 auto;
    background-image: url(/assets/img/home/4.jpg);
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0; }
    body.home .conB .conB-1 .borderbox {
      width: 130px;
      margin: 0 auto;
      padding: 30px 10px;
      border: 1px solid black;
      vertical-align: middle;
      background-color: rgba(195, 184, 182, 0.11); }
      body.home .conB .conB-1 .borderbox #baby {
        font-size: 30px;
        color: white;
        margin-bottom: 10px;
        font-family: "나눔명조";
        text-align: center; }
      body.home .conB .conB-1 .borderbox #basic {
        font-size: 20px;
        color: white;
        font-family: "나눔명조";
        text-align: center; }
  body.home .conB .conB-2 {
    height: 250px;
    width: 100%;
    margin: 0 auto;
    background-image: url(/assets/img/home/5.jpg);
    background-repeat: no-repeat;
    background-position: center; }
    body.home .conB .conB-2 h3 {
      font-family: "나눔명조";
      color: black;
      padding-top: 100px;
      margin-top: 0;
      margin-bottom: 0;
      text-align: center; }
    body.home .conB .conB-2 p {
      font-size: 10px;
      text-align: center; }
  body.home .conC {
    width: 360px;
    margin: 0 auto; }
    body.home .conC .book {
      height: 190px; }
      body.home .conC .book .bookimg {
        width: 130px;
        height: 170px;
        float: left;
        margin: 10px; }
      body.home .conC .book .bookdesc {
        float: right;
        width: 205px;
        text-align: left; }
        body.home .conC .book .bookdesc .bookdesc-1 {
          font-size: 12px;
          padding-top: 30px;
          color: gray;
          margin-bottom: 0;
          font-family: "나눔바른펜";
          line-height: 13px; }
        body.home .conC .book .bookdesc .bookdesc-2 {
          padding-top: 0;
          font-size: 12px;
          color: gray;
          margin-bottom: 0;
          font-family: "나눔바른펜";
          line-height: 15px; }
        body.home .conC .book .bookdesc .booktitle {
          font-weight: 900;
          font-size: 18px;
          font-family: "나눔바른펜"; }
        body.home .conC .book .bookdesc .writer {
          display: inline;
          font-size: 11px; }
        body.home .conC .book .bookdesc .review {
          font-size: 11px; }
  body.home .conD {
    width: 100%;
    height: 200px;
    background-color: #dcdab1; }
    body.home .conD p {
      padding-top: 80px;
      font-size: 16px;
      color: gray;
      line-height: 18px;
      text-align: center; }

footer {
  background-color: #7a7a7a;
  width: 100%;
  margin: 0 auto;
  padding: 0; }
  footer .container {
    width: 350px;
    margin: 0 auto;
    padding: 0; }
    footer .container .footA {
      color: #fefefe;
      width: 340px;
      margin: 0 auto;
      line-height: 10px;
      text-align: center; }
      footer .container .footA .info {
        width: 340px;
        padding-top: 10px;
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 15px; }
    footer .container .counselor {
      font-size: 12px;
      line-height: 17px; }
      footer .container .counselor .imo-counselor {
        font-size: 13px; }
    footer .container .copyright {
      font-size: 11px;
      line-height: 13px; }

.mdl-layout__content {
  width: 100%;
  background-color: white; }
  .mdl-layout__content .notice {
    width: 90%;
    margin: 0 auto;
    padding: 25px 0;
    background-color: white; }
    .mdl-layout__content .notice .notice-1 {
      border: 2px solid #606267;
      padding: 60px;
      background-color: white;
      font-size: 15px;
      margin-bottom: 10px; }
      .mdl-layout__content .notice .notice-1 .notice_title {
        font-family: "Noto Sans DemiLight", "Malgun Gothic";
        font-size: 1.17em;
        font-weight: bold;
        margin-bottom: 5px; }
      .mdl-layout__content .notice .notice-1 p {
        color: #858585;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -1px; }
    .mdl-layout__content .notice .notice-2 {
      border: 2px solid #9ab1d0;
      margin-bottom: 10px; }
      .mdl-layout__content .notice .notice-2 #mom_guide {
        background-color: #9ab1d0;
        color: white;
        padding: 5px;
        text-align: center; }
      .mdl-layout__content .notice .notice-2 .mom-member {
        color: #9ab1d0;
        line-height: 20px;
        letter-spacing: -1px;
        margin-bottom: 0;
        padding: 20px 10px 20px 30px; }
    .mdl-layout__content .notice .notice-3 {
      border: 2px solid #7FB096; }
      .mdl-layout__content .notice .notice-3 #imo_guide {
        background-color: #7FB096;
        text-align: center;
        color: white;
        padding: 5px; }
      .mdl-layout__content .notice .notice-3 .imo-member {
        color: #7FB096;
        line-height: 20px;
        letter-spacing: -1px;
        margin-bottom: 0;
        padding: 20px 10px 20px 30px; }
  .mdl-layout__content #urgency {
    width: 100%;
    height: 50px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px; }
    .mdl-layout__content #urgency .material-icons {
      width: 40px;
      height: 40px;
      font-size: 40px;
      margin: 0 auto;
      vertical-align: middle;
      color: #3e3737; }
  .mdl-layout__content .search_box {
    width: calc(100% - 32px);
    margin: 0 auto;
    padding-top: 20px; }
    .mdl-layout__content .search_box .customSelect2 {
      padding-left: 10px;
      height: 47px;
      width: 50%;
      border: 2px solid #3e3737;
      background-color: transparent;
      display: inline-block;
      float: left;
      border-radius: 10px 0px 0px 10px; }
      .mdl-layout__content .search_box .customSelect2 #fieldFilter {
        border: none;
        width: 70%;
        margin-top: 3px;
        margin-right: 0;
        height: 35px;
        display: inline;
        float: right; }
      .mdl-layout__content .search_box .customSelect2 .info-title {
        font-weight: bold;
        font-size: 14px;
        color: #585858;
        line-height: 40px;
        width: 27px; }
    .mdl-layout__content .search_box #list_search_btn {
      display: block;
      background-color: #3e3737;
      height: 47px;
      color: white;
      width: 50%;
      float: right;
      border-radius: 0px 10px 10px 0px; }
      .mdl-layout__content .search_box #list_search_btn .search_btn {
        width: 100%;
        text-align: center;
        line-height: 43px; }
  .mdl-layout__content #bulletinListForm #bulletinList {
    border-radius: 15px;
    border: 1px solid #7a7a7a;
    list-style: none;
    height: 60px;
    background-color: white; }
    .mdl-layout__content #bulletinListForm #bulletinList #bulletin_category {
      width: 23%;
      float: left;
      display: inline-block;
      padding: 17px 0 20px 10px;
      height: 100%;
      color: #D39D97; }
      .mdl-layout__content #bulletinListForm #bulletinList #bulletin_category p {
        margin: 0;
        font-size: 11px; }
      .mdl-layout__content #bulletinListForm #bulletinList #bulletin_category #if_imo {
        color: #A5B5CA;
        font-size: 11px; }
      .mdl-layout__content #bulletinListForm #bulletinList #bulletin_category #if_user {
        color: #8BC2A5; }
    .mdl-layout__content #bulletinListForm #bulletinList #bulletin_title {
      width: 75%;
      float: right;
      display: inline-block;
      padding: 10px 10px 10px 10px;
      font-size: 13px; }
      .mdl-layout__content #bulletinListForm #bulletinList #bulletin_title .link {
        height: 27px;
        white-space: normal;
        overflow: hidden;
        padding-top: 10px;
        text-overflow: ellipsis; }
  .mdl-layout__content #questionListForm .questionList {
    border-radius: 15px;
    border: 1px solid #7a7a7a;
    list-style: none;
    height: 60px;
    background-color: white;
    margin: 5px 8px;
    font-weight: 600; }
    .mdl-layout__content #questionListForm .questionList #question_num {
      width: 25%;
      float: left;
      display: inline-block;
      padding: 7px 0 15px 10px;
      height: 100%;
      color: #D39D97; }
      .mdl-layout__content #questionListForm .questionList #question_num p {
        margin: 0;
        font-size: 11px; }
      .mdl-layout__content #questionListForm .questionList #question_num #qna_date {
        color: #A5B5CA;
        font-size: 11px; }
    .mdl-layout__content #questionListForm .questionList #question_title {
      width: 71%;
      float: right;
      display: inline-block;
      height: 100%;
      padding: 18px 10px 10px 10px; }
  .mdl-layout__content .pull-right {
    margin-right: 20px;
    float: right;
    padding-top: 55px;
    font-size: 12px; }
    .mdl-layout__content .pull-right .material-icons {
      color: #9f9f9f;
      font-size: 20px;
      line-height: 30px; }
  .mdl-layout__content #questionForm {
    width: calc(100% - 32px);
    margin: 0 auto;
    padding-top: 90px;
    padding-bottom: 120px; }
    .mdl-layout__content #questionForm .quetion_box {
      background-color: white;
      border-radius: 15px 15px 0 15px;
      border: 1px solid #9f9f9f; }
      .mdl-layout__content #questionForm .quetion_box .question_title {
        font-size: 18px;
        font-weight: bold;
        padding: 20px 10px 10px 10px;
        border-bottom: 1px dotted #c5c5c5;
        margin: 0 10px; }
      .mdl-layout__content #questionForm .quetion_box .q_writerName {
        color: #D39D97;
        padding: 5px 20px;
        font-size: 11px; }
        .mdl-layout__content #questionForm .quetion_box .q_writerName .creDate {
          color: #A5B5CA; }
      .mdl-layout__content #questionForm .quetion_box .q_bodyText {
        font-size: 13px;
        padding: 10px 20px 20px 20px; }
    .mdl-layout__content #questionForm #answer_btn {
      width: 80px;
      padding: 7px;
      background-color: #3e3737;
      border-radius: 0px 0px 15px 15px;
      text-align: center;
      margin-right: 0px; }
      .mdl-layout__content #questionForm #answer_btn a {
        color: white;
        text-align: center;
        font-size: 13px; }
  .mdl-layout__content #questionWriteForm {
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 20px; }
    .mdl-layout__content #questionWriteForm label {
      margin-top: 10px; }
    .mdl-layout__content #questionWriteForm #bodyTextField {
      min-height: 400px;
      overflow: auto; }
    .mdl-layout__content #questionWriteForm .pull-left {
      font-size: 13px;
      border-radius: 5px;
      background-color: #414141;
      float: left; }
      .mdl-layout__content #questionWriteForm .pull-left .btn_back {
        color: white;
        padding: 5px 14px; }
    .mdl-layout__content #questionWriteForm .btn_2 {
      font-size: 13px;
      border-radius: 5px;
      background-color: #414141;
      float: right;
      height: 38.8px; }
      .mdl-layout__content #questionWriteForm .btn_2 .btn--save {
        color: white;
        padding-top: 12px; }
  .mdl-layout__content header {
    text-align: center;
    padding: 20px; }

.know_how_list .know_how_1 {
  width: 100%;
  height: 550px;
  background-image: url(/assets/img/home/23.jpg);
  background-size: cover;
  background-position: center; }
  .know_how_list .know_how_1 .know_how_con1 {
    padding: 80px 0 30px 50px; }
    .know_how_list .know_how_1 .know_how_con1 .top_text {
      font-size: 9px; }
    .know_how_list .know_how_1 .know_how_con1 .first_word {
      margin-bottom: 5px; }
    .know_how_list .know_how_1 .know_how_con1 .sec_word {
      font-size: 14px; }
  .know_how_list .know_how_1 ul {
    padding-left: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff9e; }
    .know_how_list .know_how_1 ul li {
      font-size: 12px;
      color: #383838; }

.know_how_list .know_how_2 {
  width: 100%;
  height: 550px;
  background-image: url(/assets/img/home/24.jpg);
  background-size: cover;
  background-position: center; }
  .know_how_list .know_how_2 .know_how_con1 {
    padding: 80px 50px 30px 50px;
    text-align: right; }
    .know_how_list .know_how_2 .know_how_con1 .top_text {
      font-size: 9px; }
    .know_how_list .know_how_2 .know_how_con1 h5 {
      font-weight: 600; }
  .know_how_list .know_how_2 ul {
    padding-left: 230px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.35); }
    .know_how_list .know_how_2 ul li {
      font-size: 12px;
      color: #f7f7f7; }

.know_how_list .know_how_3 {
  width: 100%;
  height: 550px;
  background-image: url(/assets/img/home/25.jpeg);
  background-size: cover;
  background-position: center; }
  .know_how_list .know_how_3 .know_how_con1 {
    padding: 80px 0 30px 50px; }
    .know_how_list .know_how_3 .know_how_con1 .top_text {
      font-size: 9px; }
    .know_how_list .know_how_3 .know_how_con1 .first_word {
      margin-bottom: 5px; }
    .know_how_list .know_how_3 .know_how_con1 .sec_word {
      font-size: 14px; }
  .know_how_list .know_how_3 ul {
    padding-left: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff9e; }
    .know_how_list .know_how_3 ul li {
      font-size: 12px;
      color: #383838; }

.addForm_desc {
  background-color: #c2c2bd;
  color: white;
  padding: 20px 30px;
  font-size: 12px; }
  .addForm_desc .desc_title {
    font-size: 16px;
    font-weight: bold;
    color: #414141;
    padding-bottom: 10px; }
  .addForm_desc p {
    margin: 0; }

.success, .waitVbank, .member_info {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center; }
  .success .material-icons, .waitVbank .material-icons, .member_info .material-icons {
    font-size: 30px;
    font-weight: bold; }
  .success h5, .waitVbank h5, .member_info h5 {
    font-family: 나눔명조;
    font-weight: bold; }
  .success ul, .waitVbank ul, .member_info ul {
    list-style-type: none;
    padding: 0;
    width: 310px;
    margin: 0 auto; }
    .success ul li, .waitVbank ul li, .member_info ul li {
      background: #fdeae8;
      margin: 5px;
      border-bottom: 1px solid #c3c3c3; }
  .success #flower, .waitVbank #flower, .member_info #flower {
    font-size: 20px;
    vertical-align: middle; }

.waitVbank {
  margin-top: 90px;
  margin-bottom: 90px; }
  .waitVbank p {
    margin: 10px; }
  .waitVbank #price {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px; }

.member_info {
  margin-top: 100px;
  margin-bottom: 120px; }
  .member_info #face {
    font-size: 55px;
    margin-bottom: 20px; }

.waitBank {
  text-align: center;
  margin-top: 160px;
  margin-bottom: 160px; }
  .waitBank .material-icons {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 4px; }
  .waitBank h5 {
    font-family: 나눔명조;
    font-weight: bold; }

.mdl-data-table {
  width: 100%; }
  .mdl-data-table td {
    text-align: left; }
  .mdl-data-table tbody tr:hover {
    background-color: white; }

body.desktop .container {
  font-size: 16px;
  width: 960px; }

body.desktop .login {
  padding-top: 150px;
  height: 700px;
  padding-left: 250px;
  padding-right: 250px; }
